import React, { useState } from 'react';
import './cookInstructions.css';

import rareImage from '../images/rare.png';
import mediumRareImage from '../images/medium-rare.png';
import mediumImage from '../images/medium.png';
import mediumWellImage from '../images/medium-well.png';
import wellDoneImage from '../images/well.png';

const CookInstructions = () => {
    const tempLines = [
        {
        label: 'Rare',
        categories: ['Grill', 'Sous Vide', 'Sear Roast'],
        temp: 120,
        instructions: [
            {
            title: 'Grill',
            steps: [
                "Preheat grill to high heat.",
                "Sear steak on each side for 2 minutes.",
                "Move steak to cooler part of the grill and cook for an additional 2-3 minutes per side until the internal temperature reaches 120°F.",
            ]
            },
            {
            title: 'Sous Vide',
            steps: [
                "Cook for 1 hour at 120°F (49°C)",
            ]
            },
            {
            title: 'Sear Roast',
            steps: [
                "Preheat the oven to 425°F (218°C).",
                "Preheat a cast iron skillet over high heat on the stovetop.",
                "Season the steak with salt and pepper and add it to the skillet.",
                "Sear the steak for 2-3 minutes on each side until browned.",
                "Transfer the skillet to the oven and roast for 6-8 minutes for rare, or until the internal temperature reaches 120°F (49°C).",
                "Remove the skillet from the oven and let the steak rest for 5 minutes before serving.",
            ]
            }
        ],
        image: rareImage,
        },
        {
        label: 'Medium Rare',
        categories: ['Grill', 'Sous Vide', 'Sear Roast'],
        temp: 130,
        instructions: [
            {
            title: 'Grill',
            steps: [
                "Preheat grill to medium-high heat.",
                "Sear steak on each side for 2 minutes.",
                "Move steak to cooler part of the grill and cook for an additional 3-4 minutes per side until the internal temperature reaches 130°F.",
            ]
            },
            {
            title: 'Sous Vide',
            steps: [
                "Cook for 1.5 hours at 130°F (54°C)",
            ]
            },
            {
            title: 'Sear Roast',
            steps: [
                "Preheat the oven to 425°F (218°C).",
                "Preheat a cast iron skillet over high heat on the stovetop.",
                "Season the steak with salt and pepper and add it to the skillet.",
                "Sear the steak for 2-3 minutes on each side until browned.",
                "Transfer the skillet to the oven and roast for 8-10 minutes for medium rare, or until the internal temperature reaches 130°F (54°C).",
                "Remove the skillet from the oven and let the steak rest for 5 minutes before serving.",
            ]
            }
        ],
        image: mediumRareImage,
        },
        {
            label: 'Medium',
            categories: ['Grill', 'Sear Roast'],
            temp: 140,
            instructions: [
            {
                title: 'Grill',
                steps: [
                "Preheat grill to medium heat.",
                "Sear steak on each side for 2-3 minutes.",
                "Move steak to cooler part of the grill and cook for an additional 4-5 minutes per side until the internal temperature reaches 140°F.",
                ]
            },
            {
                title: 'Sear Roast',
                steps: [
                "Preheat the oven to 425°F (218°C).",
                "Preheat a cast iron skillet over high heat on the stovetop.",
                "Season the steak with salt and pepper and add it to the skillet.",
                "Sear the steak for 3-4 minutes on each side until browned.",
                "Transfer the skillet to the oven and roast for 10-12 minutes for medium, or until the internal temperature reaches 140°F (60°C).",
                "Remove the skillet from the oven and let the steak rest for 5 minutes before serving.",
                ]
            }
            ],
            image: mediumImage,
        },
        {
            label: 'Medium Well',
            categories: ['Grill', 'Sear Roast'],
            temp: 150,
            instructions: [
            {
                title: 'Grill',
                steps: [
                "Preheat grill to medium heat.",
                "Sear steak on each side for 3-4 minutes.",
                "Move steak to cooler part of the grill and cook for an additional 5-6 minutes per side until the internal temperature reaches 150°F.",
                ]
            },
            {
                title: 'Sear Roast',
                steps: [
                "Preheat the oven to 425°F (218°C).",
                "Preheat a cast iron skillet over high heat on the stovetop.",
                "Season the steak with salt and pepper and add it to the skillet.",
                "Sear the steak for 4-5 minutes on each side until browned.",
                "Transfer the skillet to the oven and roast for 12-15 minutes for medium well, or until the internal temperature reaches 150°F (66°C).",
                "Remove the skillet from the oven and let the steak rest for 5 minutes before serving.",
                ]
            }
            ],
            image: mediumWellImage,
        },
        {
            label: 'Well Done',
            categories: ['Grill', 'Sear Roast'],
            temp: 160,
            instructions: [
            {
                title: 'Grill',
                steps: [
                "Preheat grill to medium heat.",
                "Sear steak on each side for 4-5 minutes.",
                "Move steak to cooler part of the grill and cook for an additional 6-7 minutes per side until the internal temperature reaches 160°F.",
                ]
            },
            {
                title: 'Sear Roast',
                steps: [
                "Preheat the oven to 425°F (218°C).",
                "Preheat a cast iron skillet over high heat on the stovetop.",
                "Season the steak with salt and pepper and add it to the skillet.",
                "Sear the steak for 5-6 minutes on each side until browned.",
                "Transfer the skillet to the oven and roast for 15-20 minutes for well done, or until the internal temperature reaches 160°F (71°C).",
                "Remove the skillet from the oven and let the steak rest for 5 minutes before serving.",
                ]
            }
            ],
            image: wellDoneImage,
        }
    ];

    const [selectedDoneness, setSelectedDoneness] = useState(tempLines[0]);

    const handleSelectedDoneness = (doneness) => {
        setSelectedDoneness(doneness);
    };    

    const TemperatureMarker = ({ doneness, isSelected, onClick }) => {
        const handleClick = () => {
        onClick(doneness);
        };
    
        const markerStyle = {
        top: `${doneness.temp}%`,
        backgroundColor: isSelected ? '#ff0000' : '#000000',
        };
    
        return (
        <div className="temperatureMarker" onMouseOver={handleClick}>
            <img src={doneness.image} alt={doneness.label} className="donenessImage" />
            <span className="markerLabel">{doneness.label}</span>
        </div>
        );
    };
    
    const InstructionsWindow = ({ range }) => {
        if (!range) {
        return null;
        }
    
        const { label, categories, temp, instructions, image } = range;
    
        return (
        <div>
            {instructions.map((instruction, index) => {
            const { title, steps } = instruction;
                
            return (
                <div key={index} className="instructionsWindow">
                <h1>How to {title} our Steak {label}</h1>
                {/* <p>Set the temperature to {temp}&#8451;</p> */}
                <ol>
                    {steps.map((step, stepIndex) => {
                    if (Array.isArray(step)) {
                        return (
                        <li key={stepIndex}>
                            <ul>
                            {step.map((subStep, subStepIndex) => (
                                <li key={subStepIndex}>{subStep}</li>
                            ))}
                            </ul>
                        </li>
                        );
                    } else {
                        return <li key={stepIndex}>{step}</li>;
                    }
                    })}
                </ol>
                </div>
            );
            })}
        </div>
        );
    };
    
    
    
    return (
        <div className="cookInstructionsContainer">
            <div className="temperatureMarkersContainer">
                {tempLines.map((doneness, index) => (
                <TemperatureMarker
                    key={index}
                    doneness={doneness}
                    isSelected={doneness === selectedDoneness}
                    onMouseOver={() => handleSelectedDoneness(doneness)}
                    onClick={() => handleSelectedDoneness(doneness)}
                />
                ))}
            </div>
            <InstructionsWindow range={selectedDoneness} />
        </div>
    );
};
    
export default CookInstructions;