import React, { useContext } from 'react';
import { FiShoppingCart } from 'react-icons/fi';
import { CartContext } from './CartContext';
import logo from './logo.png';
import logoTape from './logoTape.png';
import './checkoutHeader.css';


function CheckoutHeader() {
    const { toggleCart } = useContext(CartContext);

    return (
        <header className="checkout-header">
            <nav className="nav-checkout-container">
                <div className="checkout-logo-container">
                    <img src={logo} alt="Checkout Logo" className="checkout-logo" />
                    <img src={logoTape} className="logo_tape" alt="Kleevr Logo tape"></img>
                </div>
                <ul className="checkout-navi">
                    {/* <li>
                        <button className="checkout-cart-button" onClick={() => toggleCart()}>
                            <FiShoppingCart className="checkout-cart-icon" />
                            Cart
                        </button>
                    </li> */}
                    {/* <li>
                        <a href="tel:(888) 888-8888" className="checkout-header-link">(888) 888-8888</a>
                    </li> */}
                    <li>
                        {/* <a href="https://www.example.com/live-chat" className="checkout-header-link">Live Chat</a> */}
                        {/* <a href="/" className="checkout-header-link">Go Back</a> */}
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default CheckoutHeader;
