import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const CartContext = createContext();

// Initial state with empty values
const initialBillingInfo = {
    b_firstName: '',
    b_lastName: '',
    b_address: '',
    b_address2: '',
    b_city: '',
    b_state: '',
    b_postalCode: '',
};

const initialCustomerInfo = {
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    termsConditions: false,
    useSameBilling: true,
};

let apiUrl, STORE_ID;

if (process.env.NODE_ENV === 'development') {
    apiUrl = 'http://jd.luxurystatus.com/admin/api.php';
    STORE_ID = '25';
} else {
    apiUrl = 'https://luxurystatus.com/admin/api.php';
    STORE_ID = '24';
}


export const CartProvider = ({ children }) => {
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]); //what the user is trying to purchase
    const [cartDisplay, cartWasUpdated] = useState(); //what the user can actually purchase.
    const [customerInfo, setCustomerInfo] = useState(initialCustomerInfo);
    const [billingInfo, setBillingInfo] = useState(initialBillingInfo);
    const [promoCode, setPromoCode] = useState(false);
    const [checkedOut, setCheckedOut] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [shippingCosts, setShippingCosts] = useState({
        standard: 4.99,
        express: 8.99,
    });
    const [shippingMethod, setShippingMethod] = useState('express');

    useEffect(() => {
        const storedCartItems = localStorage.getItem('cartItems');

        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }

    }, []);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };

    const addToCart = async (item, quantity) => {       
        const newItem = {
            item: { ...item }, // Create a new object to avoid modifying the original item
            quantity: quantity,
        };
    
        const itemAvailability = await checkAvailability([newItem]);
    
        if (itemAvailability) {
            let updatedCartItems;
    
            const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.item.item_id === newItem.item.item_id);
    
            if (quantity === 0) {
                if (existingItemIndex !== -1) {
                    updatedCartItems = [...cartItems];
                    updatedCartItems.splice(existingItemIndex, 1); // Remove the item from the array
                }
            } else {
                if (existingItemIndex !== -1) {
                    updatedCartItems = [...cartItems];
                    updatedCartItems[existingItemIndex].quantity += newItem.quantity;
                } else {
                    updatedCartItems = [...cartItems, newItem];
                }
            }
    
            updatedCartItems = updatedCartItems.filter((cartItem) => cartItem.quantity > 0);

            setCartItems(updatedCartItems);            
            const returnedCartItems = await checkAvailability(updatedCartItems);
    
            if (returnedCartItems) {
                cartWasUpdated(returnedCartItems);
            } else if (updatedCartItems.length === 0) {
                cartWasUpdated(null); // Pass null to indicate that the cart is empty
            }
            return true;
        }
    };

    // Calculate subtotal
    const calculateSubtotal = (cartDisplay) => {
        let subtotal = 0;
        if (cartDisplay && cartDisplay.cart_items) {
            cartDisplay.cart_items.forEach((item) => {
                subtotal += item.item_cost * item.quantity;
            });
        }
        // const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return subtotal; //.toLocaleString(undefined, options);
    };

    const calculateShipping = () => {
        const subtotal = calculateSubtotal(cartDisplay);

        if (subtotal > 175) {
            return 0; // Free shipping
        } else {
            return shippingCosts[shippingMethod];
        }
    };

    // Calculate taxes
    const calculateTaxes = (cartDisplay) => {
        const taxRate = 0.06; // Assuming a tax rate of 6%
        const subtotal = calculateSubtotal(cartDisplay);
        
        return (subtotal * taxRate);
    };

    // Calculate total
    const calculateTotal = (cartDisplay) => {
        const subtotal = calculateSubtotal(cartDisplay);
        const taxes = calculateTaxes(cartDisplay);
        const shippingCost = calculateShipping();

        const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return (subtotal + taxes + shippingCost).toLocaleString(undefined, options);
    };


    const phoneHome = async (customerData, billingData) => {
        let formData = new FormData();
        formData.append('action', 'saveCustomerInfo');
        formData.append('store_id', STORE_ID);
        formData.append('customerData', 
            JSON.stringify({
                customerData: customerData,
                billingData: billingData && billingData.b_firstName ? billingData: ''
            })
        );

        try {
            const response = await axios.post(apiUrl, formData);
            // success = response.data.success === true;
            // if (success) {
                // console.log(response.data.data);
                // return response.data.data;
            // }
        } catch (error) {
        }

    }

    const checkAvailability = async (items, promo_code=null) => {
        let success = false;

        if (items) {
            const cartItems = items.map((cartItem) => {
                return {
                    item_id: cartItem.item.item_id,
                    quantity: cartItem.quantity,
                };
            });


            let formData = new FormData();
            formData.append('action', 'updateCart');
            formData.append('store_id', STORE_ID);
            formData.append('cartItemList', JSON.stringify(cartItems));

            if (promo_code) {
                setPromoCode(promo_code);
                formData.append('promo_code', promo_code);
            }

            try {
                const response = await axios.post(apiUrl, formData);
                success = response.data.success === true;

                if (success) {
                    return response.data.data;
                }
            } catch (error) {
            }
        }

        return success;
    };

    const openCartView =() => {
        setIsCartOpen(true);

    }

    const quickCartVision = () => {
        setIsCartOpen(true);
        setTimeout(() => {
            setIsCartOpen(false);
        }, 3000);
    };

    const placeOrder = async (paymentIntent, navigate) => {
        let formData = new FormData();
        formData.append('action', 'createOrder');
        formData.append('store_id', STORE_ID);
        formData.append('customerData', JSON.stringify({ customerData: customerInfo, billingData: (billingInfo.b_firstName ? billingInfo: ''), cartDisplay, promoCode: promoCode, paymentIntent: paymentIntent }));
        setCheckedOut(true);

        try {
            const response = await axios.post(apiUrl, formData);
            let success = response.data.success === true;

            if (success) {
                setOrderData(response.data.data);
                navigate('/success');
            }
        } catch (error) {
            // navigate('/failure');
        }
    }

    const FailureComponent = ({ errorMessage }) => {
        return (
            <div className="failure-container">
                <h2>Order Failed</h2>
                <p>Oops! Something went wrong with your order.</p>
                <p>Error Message: {errorMessage}</p>
                <p>Please review your order details and try again later.</p>
            </div>
        );
    };

    return (
        <CartContext.Provider value={{
            STORE_ID,
            isCartOpen,
            toggleCart,
            addToCart,
            cartItems,
            cartDisplay,
            checkAvailability,
            cartWasUpdated,
            phoneHome,
            placeOrder,
            checkedOut,
            setCustomerInfo,
            setBillingInfo,
            customerInfo,
            billingInfo,
            orderData,
            setOrderData,
            quickCartVision,
            openCartView,
            apiUrl,
            calculateSubtotal,
            calculateShipping,
            calculateTaxes,
            calculateTotal,
            setShippingMethod,
            shippingMethod
        }}>
            {children}
        </CartContext.Provider>
    );
};