import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useContext } from 'react';
import { CartContext } from './CartContext';
import { FiShoppingCart } from 'react-icons/fi';
import axios from 'axios';
import './modal.css';
import './responsive.css';
import leftButton from './left-arrow.png';
import rightButton from './right-arrow.png';
import Reviews from './Reviews';


Modal.setAppElement('#root');

const CustomModal = (props) => {
    let steak = props.individualSteakData;
    let steakDetails = [];

    const [showItemUnselectedError, setShowItemUnselectedError] = useState(false);
    const [showLargeImage, setShowLargeImage] = useState(true);
    const [largeSteakImg, setLargeSteakImage] = useState(steak.product_image_file_path);
    const [quantity, setQuantity] = useState(1);
    const [activeTab, setActiveTab] = useState('description');
    let {showViewCartButton} = props;
    const {STORE_ID, addToCart, quickCartVision, apiUrl, openCartView} = useContext(CartContext);
    const [checked] = useState(true);


    let product_description_brief = steak.product_description_brief;
    let product_description = steak.product_description;

    const [availableItems, setAvailableItems] = useState([]); // Update items using useState hook
    const [additionalImages, setAdditionalImages] = useState([]);
    const [currentItem, setSelectedItem] = useState([{}]);


    useEffect(() => {
        // Make API call to retrieve product data

        let product_id = steak.product_id;
        let formData = new FormData();
        formData.append('action', 'getProductDetails');
        formData.append('store_id', STORE_ID);
        formData.append('product_id', product_id);

        axios.post(apiUrl, formData)
            .then((r) => {
                let success = r.data.success == true;
                if (success) {
                    steakDetails = r.data.data;
                    setAvailableItems(steakDetails.items);
                    setSelectedItem(steakDetails.items[steakDetails.items.length - 1])
                    setAdditionalImages(steakDetails.items[0].images.additionalImages);
                }
            })
            .catch(error => {
            });
    }, []);

    const closeModal = () => {
        props.onCloseModal();
    }

    const incrementQuantity = () => {
        setQuantity(quantity + 1);
    };

    const decrementQuantity = () => {
        if (quantity > 1) {
        setQuantity(quantity - 1);
        }
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const ReOrientItem = (item) => {
        setSelectedItem(item);
    }
   
    return (
        <div className="modal-container">
            <Modal isOpen={props.isModalVisible} onRequestClose={closeModal} contentLabel="Get Kleevr Item Detail Modal" id="kleevr_modal">
                <div className="modal-content">
                    <div className="modal-image">
                        <div className="image-container">
                            <h2 className="product-title">{steak.product_name}</h2>
                            <RatingStars rating={5} />                                                    
                            <img
                            src={largeSteakImg ? largeSteakImg : steak.small_image}
                            alt={steak.product_name}
                            className="kenburns-image"
                            />
                        </div>
                        <div className="small-image-boxes">
                            {additionalImages ? (
                            additionalImages.map((addImg) => (
                                <div className="small-image-box" key={addImg.item_image_id}>
                                <img
                                    src={addImg.item_image_file_path}
                                    alt={steak.product_name}
                                    onClick={() => setLargeSteakImage(addImg.item_image_file_path)}
                                />
                                </div>
                            ))
                            ) : (
                            ''
                            )}
                        </div>
                    </div>

                    <div id="product_details">
                        <p className="description">{product_description_brief}</p>
                        <div className="details">
                            <div className="price-section">
                                {/* <p className="retail-price">${steak.product_retail_price}</p> */}
                                {/* <p className="our-price">${steak.product_cost}</p> */}
                            </div>
                            <div className="size">
                                <div className="circle-options">
                                    {availableItems.map((item) => (
                                        <label key={item.size_id} className="size-option">
                                        <input
                                            required="required"
                                            type="radio"
                                            id={item.size_id}
                                            name="size"
                                            value={item.size}
                                            onClick={() => ReOrientItem(item)}
                                            defaultChecked={checked}
                                        />
                                        <span className="size-label">
                                            <span className="item-size">{item.size}</span>
                                            <span className="item-price">${item.item_cost}</span>
                                        </span>
                                        </label>
                                    ))}
                                </div>
                                {showItemUnselectedError && <p className="error-message">Please select a size.</p>}
                            </div>
                            <div className="quantity">
                                <div className="quantity-counter">
                                    <button className="counter-button" onClick={decrementQuantity}>
                                    -
                                    </button>
                                    <span className="counter-value">{quantity}</span>
                                    <button className="counter-button" onClick={incrementQuantity}>
                                    +
                                    </button>
                                    <button className="addToCart" onClick={() => {
                                        if (!currentItem.item_id) {
                                            setShowItemUnselectedError(true);
                                            return;
                                        }
                                        setShowItemUnselectedError(false);
                                        addToCart(currentItem, quantity);
                                        quickCartVision();
                                        showViewCartButton = !showViewCartButton;
                                    }}>
                                        Add to Cart
                                    </button>
                                    <div className="view-cart-button">
                                        <button onClick={() => { openCartView(); }}>
                                            <FiShoppingCart className="cart-icon" />
                                        </button>
                                    </div>
                                </div>
                            </div>                           
                        </div>

                        <div className="modal-tabs">
                            <button className={`modal-tab ${activeTab === 'description' ? 'active' : ''}`} onClick={() => handleTabChange('description')}>Description</button>
                            <button className={`modal-tab ${activeTab === 'reviews' ? 'active' : ''}`} onClick={() => handleTabChange('reviews')}>Reviews</button>
                            {/* <button className={`modal-tab ${activeTab === 'info' ? 'active' : ''}`} onClick={() => handleTabChange('info')}>More Info</button> */}

                            <div className="modal-content-section">
                                {activeTab === 'description' && (
                                    <div>
                                        {/* <h3>Product Description</h3> */}
                                        <p>{product_description}</p>
                                    </div>
                                )}
                                {activeTab === 'reviews' && (
                                    <Reviews ProductId = {steak.product_id} />
                                )}
                                {activeTab === 'info' && (
                                    <div className="more-info">
                                        <h3>More Info</h3>
                                        <ul>
                                            <li>Product SKU: {steak.sku}</li>
                                            <li>Weight: {steak.weight}</li>
                                            <li>Ingredients: {steak.ingredients}</li>
                                            <li>Nutritional Information: {steak.nutritionalInfo}</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* <div id="left_button" className="nxt_btn">
                        <a href="" alt="speak on it">
                            <img src={leftButton} alt="left arrow to next item" />

                        </a>
                    </div>
                    <div id="right_button" className="nxt_btn">
                        <a href={rightButton} alt="speak on it" >
                            <img src={rightButton} alt="left arrow to next item" />
                        </a>
                    </div> */}
                    <button className="close-button" onClick={closeModal}>Close</button>
                </div>
            </Modal>
        </div>
    );
}


const RatingStars = ({ rating }) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    const renderStars = () => {
        const stars = [];

        for (let i = 0; i < filledStars; i++) {
            stars.push(<Star key={i} filled />);
        }

        if (hasHalfStar) {
            stars.push(<Star key="half" half />);
        }

        const remainingStars = 5 - stars.length;

        for (let i = 0; i < remainingStars; i++) {
            stars.push(<Star key={`empty-${i}`} />);
        }

        return stars;
    };

    return <div className="rating-stars">{renderStars()}</div>;
};

const Star = ({ filled, half }) => {
    let starClass = 'star';

    if (filled) {
        starClass += ' filled';
    }

    if (half) {
        starClass += ' half';
    }

    return <span className={starClass}>&#9733;</span>;
};


export default CustomModal;