import React, { useEffect, useContext } from 'react';
import { CartContext } from './CartContext';
import { useNavigate } from 'react-router-dom';
import './ordersuccess.css';

const SuccessComponent = () => {
    const navigate = useNavigate();
    const { cartDisplay, customerInfo } = useContext(CartContext);
    const {calculateTotal, calculateTaxes, calculateShipping, calculateSubtotal, shippingMethod, setShippingMethod} = useContext(CartContext);

    if (!cartDisplay) {
        navigate('/');
    }
    
    if (!cartDisplay) {
        return null; // or display an appropriate message
      }
      
    const { cart_items, total_quantity, total_cost } = cartDisplay;
      

    return (
        <div className="success-container">
            <h2>Order Placed Successfully!</h2>
            <p>Thank you for your order. We're excited to fulfill your steak cravings!</p>
            <div className="order-details">
                <ul>
                    {cart_items.map((item) => (
                        <li key={item.item_id}>
                            <div className="item-image">
                                <img src={item.product_image_file_path} alt={item.item_name} />
                            </div>
                            <div className="item-info">
                                <h4>{item.item_name}</h4>
                                <p>Size: {item.size_name}</p>
                                <p>Quantity: {item.quantity}</p>
                                <p>Price: ${item.item_cost.toFixed(2)}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="order-summary">
                    <div className="subtotal">
                        <div className="subtotal-label">Subtotal:</div>
                        <div className="subtotal-amount">${calculateSubtotal(cartDisplay).toFixed(2)}</div>
                    </div>
                    <div className="taxes">
                        <div className="taxes-label">Taxes:</div>
                        <div className="taxes-amount">${calculateTaxes(cartDisplay).toFixed(2)}</div>
                    </div>
                    <div className="shipping">
                        <div className="shipping-label">Shipping:</div>
                        <div className="shipping-amount">{0 == calculateShipping(cartDisplay) ? `FREE`:`$` + calculateShipping(cartDisplay) }</div>
                    </div>                                        
                    <div className="total">
                        <div className="total-label">Total:</div>
                        <div className="total-amount">${calculateTotal(cartDisplay)}</div>
                    </div>                    
                </div>
            </div>
            <div className="shipping-details">
                <h4>Order Will be delivered to:</h4>
                <p>{customerInfo.firstName} {customerInfo.lastName}</p>
                <p>{customerInfo.email}</p>
                <p>{customerInfo.address}</p>
                <p>{customerInfo.address2}</p>
                <p>{customerInfo.city}, {customerInfo.state} {customerInfo.postalCode}</p>
            </div>
        </div>
    );
};

export default SuccessComponent;