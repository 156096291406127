import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import CheckoutHeader from './components/CheckoutHeader';
import Main from './components/Main';
import Checkout from './components/Checkout';
import Footer from './components/Footer';
import SuccessComponent from './components/OrderSuccess';

import './App.css';

const App = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route
                        path=""
                        element={
                            <>
                                <Header />
                                <Main />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/checkout"
                        element={
                            <>
                                <CheckoutHeader />
                                <Checkout />
                                <Footer />
                            </>
                        }
                    />
                    <Route
                        path="/success"
                        element={
                            <>
                                <CheckoutHeader />
                                <SuccessComponent />
                                <Footer />
                            </>
                        }
                    />                    
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
