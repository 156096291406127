import React, { useEffect, useState, useContext, useRef } from 'react';
import { CartContext } from './CartContext';
import { Link } from 'react-router-dom';
import './cart.css';
// import './responsive.css';

const Cart = () => {
    const {cartItems, isCartOpen, toggleCart, cartDisplay, addToCart} = useContext(CartContext);
    const cartRef = useRef(null);
  
    useEffect(() => {
        if (isCartOpen) {
            document.body.classList.add('cart-open');
        } else {
            document.body.classList.remove('cart-open');
        }
  
        return () => {
        };

    }, [isCartOpen, cartItems]);

    const calculateSubtotal = (cartDisplay) => {
        let subtotal = 0;
        if (cartDisplay && cartDisplay.cart_items) {
            cartDisplay.cart_items.forEach((item) => {
                subtotal += item.item_cost * item.quantity;
            });
        }

        const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
        return subtotal.toLocaleString(undefined, options);
    };    


    return (
        <div ref={cartRef} className={`cart-container ${isCartOpen ? 'open' : ''}`}>
            <div className="cart-content">
                <div className="cart-header">
                    <h2 className="cart-title">Your Cart</h2>
                    <button className="cart-close-button" onClick={toggleCart}>
                        <span>Close</span>
                    </button>
                </div>
                <div className="cart-items">
                    {cartDisplay ? (
                        cartDisplay.cart_items.map((item) => (
                            <div className="cart-item" key={item.item_id}>
                                <div className="item-image">
                                    <img src={item.product_image_file_path} alt={item.item_name} />
                                </div>
                                <div className="item-details">
                                    <div className="item-name">
                                        {item.item_name} ({item.size_name})
                                    </div>
                                    <div className="item-quantity">
                                        <button
                                        id='item_decrement_button'
                                        onClick={() => item.quantity >= 1 && addToCart(item, -1)}
                                        >
                                        -
                                        </button>
                                        <span>x {item.quantity}</span>
                                        <button id='item_increment_button' onClick={() => addToCart(item, 1)}>+</button>
                                    </div>
                                    <div className="item-price">${item.item_cost}</div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Nothing in your cart yet...</p>
                    )}
                </div>
                <div className="cart-summary">
                    <div className="cart-subtotal">
                        <span className="subtotal-label">Subtotal:</span>
                        <span className="subtotal-amount">${calculateSubtotal(cartDisplay)}</span>
                    </div>
                    {cartDisplay ? (
                        <div className="cart-checkout-button">
                            <Link to="/checkout">Proceed to Checkout</Link>
                        </div>
                    ):(
                        null
                    )}
                        {/* Proceed to Checkout */}
                </div>
            </div>
        </div>
    );
};

export default Cart;