import React, { useContext, useState } from 'react';

import { FiShoppingCart, FiMenu } from 'react-icons/fi';
import './header.css'; // Import the CSS file
import { CartContext } from './CartContext';
import { Link } from 'react-scroll';
import logo from './logo.png';
import logoTape from './logoTape.png';



function Header() {
    const { toggleCart } = useContext(CartContext);
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    }

    return (
        <header className="header">
            <nav className="container">
                <img src={logo} className="logo" alt="Kleevr Logo"></img>
                <img src={logoTape} className="logo_tape" alt="Kleevr Logo tape"></img>
                {/* Mobile Menu */}
                <div id="menu-container">
                    <button className="mobile-menu-btn">
                        <FiMenu className='mobile-menu-icon' onClick={toggleMenu} />
                    </button>
                    <ul className={`navi ${menuVisible ? '' : 'hidden'}`}>
                        <li className="navi-item">
                            <Link
                                to="about_us"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="navi-link"
                            >
                                About Kleevr
                            </Link>
                        </li>
                        <li className="navi-item">
                            <Link
                                to="menu"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="navi-link"
                            >
                                Our Products
                            </Link>
                        </li>
                        <li className="navi-item">
                            <Link
                                to="cook_instructions"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="navi-link"
                            >
                                Instructions
                            </Link>
                        </li>
                        <li className="navi-item">
                            <Link
                                to="faq"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="navi-link"
                            >
                                FAQ
                            </Link>
                        </li>
                        <li className="navi-item">
                            <button className="cart-button" onClick={() => toggleCart()}>
                                <FiShoppingCart className="cart-icon" />
                                Cart
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;