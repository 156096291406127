import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import CustomModal from './Modal';
import Faq from './Faq';
import CookInstructions from './CookInstructions';
import Cart from './Cart';
import { CartContext } from './CartContext';

import './main.css';
import './menu.css';
import './responsive.css';

import banner1Mp4 from './banner-1.mp4';
import banner2Mp4 from './banner-2.mp4';
import banner3Mp4 from './banner-3.mp4';
import banner4Mp4 from './banner-4.mp4';

import { Link } from 'react-scroll';
import axios from 'axios';


// Import trust icons
// import sslSecuredIcon from './ssl-secured-icon.png';
// import paymentProviderLogos from './payment-provider-logos.png';
// import securitySeals from './security-seals.png';
// import trustBadges from './trust-badges.png';
// import socialProofIcons from './social-proof-icons.png';





const Main = () => {
    const [isCartOpen, setIsCartOpen] = useState(false);

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };   

    return (
        <main>
            <section className="banner">
                <Welcome />
                <BannerVideo />
            </section>
            {/* <section className="hero">
            </section> */}
            <section className="about">
                <AboutUs />
            </section>
            <section className="menu">
                <Menu />
            </section>
            <section className="cook_instructions">
                <CookInstructions />
            </section>
            <section className="faq">
                <Faq />
            </section>
            <Cart isCartOpen={isCartOpen} onCloseCart={toggleCart} />
        </main>
    );
}

const BannerVideo = () => {
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const bannerVideos = [banner1Mp4, banner2Mp4, banner3Mp4, banner4Mp4];
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;
    
        // const playNextBannerVideo = () => {
        //   setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % bannerVideos.length);
        // };

        const playNextBannerVideo = () => {
            setCurrentBannerIndex(currentBannerIndex + 1);
        };
    
        const handleVideoEnded = () => {
            playNextBannerVideo();
            //   videoElement.play();
        };
    
        videoElement.addEventListener("ended", handleVideoEnded);
        return () => {
            videoElement.removeEventListener("ended", handleVideoEnded);
        };
    }, [bannerVideos]);
    
    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.muted = true;
            videoElement.play();
        }
    }, [currentBannerIndex]);

    return (
        <video ref={videoRef} playsInline={true} muted>
            <source src={bannerVideos[currentBannerIndex]} type="video/mp4" />
        </video>
    );
};

const Welcome = () => {
    return (
        <section id="welcome">
            <div className="welcome-content">
                <h1>Welcome to Kleevr</h1>
                <p>Delivering the finest hand-selected steaks to your doorstep.</p>
                <Link
                    to="menu"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="cta-button"
                >
                    Explore Our Menu
                </Link>
            </div>
        </section>

    );
};

const AboutUs = () => {
    return (
        <div id="about_us">
            <h2>About Us</h2>
            <p>
                Welcome to Kleevr, a modern company dedicated to delivering the finest hand-selected steaks right to your doorstep. We take pride in curating a selection of exceptional steaks sourced from multiple suppliers, with a particular focus on the renowned Japanese steaks, including the highly sought-after Wagyu.
                At Kleevr, we understand that steak connoisseurs crave variety and quality. That's why we offer an extensive range of top desired steaks, including Tomahawks, Filets, and more. Each steak is carefully chosen to meet our stringent standards for marbling, tenderness, and flavor, ensuring an unparalleled dining experience.
            </p>
            <p>
                What sets Kleevr apart is our commitment to convenience. We believe that exceptional steaks should be accessible to everyone, regardless of their location. That's why we provide a seamless delivery service, bringing the succulent flavors of our premium steaks directly to your home. With our efficient and reliable delivery system, you can indulge in restaurant-quality steaks without leaving the comfort of your own kitchen.
                We take pride in our passion for steak and the joy it brings to our customers. Our mission is to exceed your expectations and create memorable dining experiences that elevate your love for steak.
            </p>           
            <p>
                Experience the excellence of Kleevr and treat yourself to the finest American Wagyu steaks and other exceptional cuts. Join us in savoring the rich flavors, tender textures, and unmatched quality that make our steaks a true indulgence. Place your order today and discover the convenience and delight of having top-quality steaks delivered right to your doorstep.
            </p>
            {/* Display trust icons */}
            {/* <div className="trust-icons">
                <img src={sslSecuredIcon} alt="SSL Secured" />
                <img src={paymentProviderLogos} alt="Payment Providers" />
                <img src={securitySeals} alt="Security Seals" />
                <img src={trustBadges} alt="Trust Badges" />
                <img src={socialProofIcons} alt="Social Proof Icons" />
            </div> */}
        </div>
    );
};

const Menu = () => {
    const {STORE_ID, apiUrl} = useContext(CartContext);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [steaks, setProducts] = useState([]);
    const [isAddToCartPage, setIsAddToCartPage] = useState(false);

    const handleMenuItemClick = (menuItemData) => {
        setSelectedMenuItem(menuItemData);
        setIsModalVisible(true);
        setIsAddToCartPage(false)
    };

    useEffect(() => {
        let formData = new FormData();
        formData.append('action', 'getProducts');
        formData.append('store_id', STORE_ID);

        axios.post(apiUrl, formData)
            .then((r) => {
                let success = r.data.success == true;
                if (success) {
                    let productData = r.data.data;
                    setProducts(productData);
                }
            })
            .catch(error => {
            });
    }, []);
    
    return (
        <div className="menu-container">
            {steaks.map((steak, index) => (
                <div className="menu-item" key={index} onClick={() => { handleMenuItemClick(steak); }}>
                    <img src={steak.product_image_file_path} alt={steak.product_name} />
                    <div className="menu-item-text">
                        <h3>{steak.product_name}</h3>
                        <span className="price">${steak.product_cost}</span>
                    </div>
                </div>
            ))}
            {isModalVisible && (
                <CustomModal
                    isModalVisible={isModalVisible}
                    individualSteakData={selectedMenuItem}
                    onCloseModal={() => setIsModalVisible(false)}
                    addToCartPage={isAddToCartPage}
                />
            )}
        </div>
    );
};

export default Main;