import React, { useState, useContext, useEffect } from 'react';
import { CartContext } from './CartContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CustomerData from './CustomerData';
import { useNavigate } from 'react-router-dom';
import './checkout.css';
import './responsive.css';

const Checkout = () => {
    const navigate = useNavigate();
    const {STORE_ID, cartDisplay, cartItems, checkAvailability, cartWasUpdated, placeOrder, checkedOut} = useContext(CartContext);
    const {calculateTotal, calculateTaxes, calculateShipping, calculateSubtotal, shippingMethod, setShippingMethod} = useContext(CartContext);
    const {customerInfo, apiUrl} = useContext(CartContext);
    // const [shippingMethod, setShippingMethod] = useState('express');


    
    const [promoCode, setPromoCode] = useState('');
    const [showOverlay, setShowOverlay] = useState(true);

    useEffect(() => {
        if (!cartDisplay || !cartDisplay.cart_items || cartDisplay.cart_items.length === 0) {
            // Redirect the user back to the main page
            navigate('/');
        }
        
        window.scrollTo(0, 0);
    }, [cartDisplay, navigate]);
    
    let stripePromise;
    if (process.env.NODE_ENV === 'development') {
        stripePromise = loadStripe('pk_test_51NLvOUCyubPBOROhF36MOWwxLQI2Rs3vdaw6YSJ7tTXmFbD8qU2ohTn3OyAlotCqLY2taoEXxbpnj2VgHpFSVHt800OnHn2b0e');
    } else {
        stripePromise = loadStripe('pk_live_51NLvOUCyubPBOROhO9URNLC7GR5di3lMfERqKVbXeiGb6X5rkiGCpxZK1aCm6Ea7pR76FXQPqZvWyIg8OtH8n9Yd00gvnm5ez7');
    }

    const stripe = stripePromise;
    
    // Handle form submission and payment processing
    const handleSubmit = async (event, elements, stripe) => {
        event.preventDefault();

        // Create a payment intent on your server and retrieve the client secret
        const params = new URLSearchParams();
        const amount = Math.round(calculateTotal(cartDisplay) * 100);
      
        params.append('store_id', STORE_ID);
        params.append('action', 'fetchPaymentIntent');
        params.append('amount', amount.toString());
        params.append('currency', 'usd');
      
        const clientSecret = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params.toString(),
        })
          .then((res) => res.json())
          .then((data) => data.data.clientSecret);
      
          // Call Stripe confirmCardPayment method to complete the payment
        const cardElement = elements.getElement(CardElement);

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: `${customerInfo.firstName} ${customerInfo.lastName}`,
                    email: customerInfo.email,
                    address: {
                        line1: customerInfo.address,
                        line2: customerInfo.address2,
                        city: customerInfo.city,
                        state: customerInfo.state,
                        postal_code: customerInfo.postalCode,
                    },
                },
            },
        });
     
        if (error) {
        
        } else {
            placeOrder(paymentIntent, navigate);
        }
    };

    
    const applyPromoCode = async () => {
        const returnedCartItems = await checkAvailability(cartItems, promoCode);
        cartWasUpdated(returnedCartItems);
    }

    const promoChanged = (e) => {
        setPromoCode(e.target.value);
    }

    const handleShippingMethodChange = (e) => {
        setShippingMethod(e.target.value);
    };    

    const cardElementOptions = {
        // Customize the appearance of the CardElement
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };

    return (
        <div className="checkout-container">
            <Elements stripe={stripePromise}>
                <div className="checkoutWrap">
                    <CustomerData setShowOverlay={setShowOverlay} />
                    <div className="checkoutBox">
                        <div className={`payment-container`}>
                            <div className={showOverlay ? 'overlay' : ''}>
                                <div className="overlay-logo">
                                    {/* <img src={logo} alt="Logo" /> */}
                                </div>
                                <div className="totals">
    
                                    
                                    {cartDisplay ? cartDisplay.cart_items.map((item) => (
                                        <div className="cart-item" key={item.item_id}>
                                            <div className="item-image">
                                                <img src={item.product_image_file_path} alt={item.item_name} />
                                            </div>
                                            <div className="item-details">
                                                <div className="item-name">{item.item_name} ({item.size_name})</div>
                                                <div className="item-quantity">X {item.quantity}</div>
                                                <div className="item-price">${item.item_cost.toFixed(2)}</div>
                                            </div>
                                        </div>
                                    )): ''}

                                    <div className="promo-code">
                                        <div className="promo-input">
                                            <input type="text" id="promo_code" placeholder="Enter promo code" onChange={promoChanged} />
                                            <button className='checkout-button' onClick={() => applyPromoCode()}>Apply</button>
                                        </div>
                                    </div>
                                    {/* <div className="checkout-section-heading">Shipping Method</div> */}
                                    <div className="shipping-methods">
                                        {/* <div className={`shipping-method ${shippingMethod === 'standard' ? 'selected' : ''}`}>
                                            <input 
                                            type="radio" 
                                            id="standard-shipping" 
                                            name="shipping-method" 
                                            value="standard" 
                                            checked={shippingMethod === 'standard'} 
                                            onChange={handleShippingMethodChange}
                                            />
                                            <label htmlFor="standard-shipping" className="shipping-label">Standard Shipping</label>
                                            <span className="shipping-price">$4.99</span>
                                        </div> */}
                                        <div className={`shipping-method ${shippingMethod === 'express' ? 'selected' : ''}`}>
                                            <input 
                                            type="radio" 
                                            id="express-shipping" 
                                            name="shipping-method" 
                                            value="express" 
                                            defaultChecked={shippingMethod === 'express'} 
                                            onChange={handleShippingMethodChange} 
                                            />
                                            <label htmlFor="express-shipping" className="shipping-label">Overnight Shipping</label>
                                            <span className="shipping-price">{0 == calculateShipping(cartDisplay) ? `FREE` : `$8.99`}</span>
                                        </div>
                                    </div>
                                    <div className="checkout-totals">
                                        <div className="subtotal">
                                            <div className="subtotal-label">Subtotal:</div>
                                            <div className="subtotal-amount">${calculateSubtotal(cartDisplay).toFixed(2)}</div>
                                        </div>
                                        <div className="taxes">
                                            <div className="taxes-label">Taxes:</div>
                                            <div className="taxes-amount">${calculateTaxes(cartDisplay).toFixed(2)}</div>
                                        </div>
                                        <div className="shipping">
                                            <div className="shipping-label">Shipping:</div>
                                            <div className="shipping-amount">{0 == calculateShipping(cartDisplay) ? `FREE`:`$` + calculateShipping(cartDisplay) }</div>
                                        </div>                                        
                                        <div className="total">
                                            <div className="total-label">Total:</div>
                                            <div className="total-amount">${calculateTotal(cartDisplay)}</div>
                                        </div>
                                    </div>
                                </div>

                                {/* Include Stripe payment form */}
                                <div className="payment-form">
                                    <ElementsConsumer>
                                        {({ elements, stripe }) => (
                                            <form id="actual-payment-form" onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                                                <h2 className="checkout-section-heading">Payment</h2>
                                                <div className="card-element-container">
                                                    <CardElement options={cardElementOptions} />
                                                </div>
                                                <button type="submit" className="checkout-button payment-button" disabled={checkedOut}>
                                                    Checkout Now
                                                </button>
                                            </form>
                                        )}
                                    </ElementsConsumer>
                                </div>

                            </div>                            
                        </div>
                    </div>
                </div>
            </Elements>
        </div>
    );
};

export default Checkout;