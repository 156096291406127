import React, { useState, useContext, useEffect, useRef } from 'react';
import { CartContext } from './CartContext';



const customerData = ({ setShowOverlay }) => {
    const {phoneHome, customerInfo, billingInfo, setCustomerInfo, setBillingInfo} = useContext(CartContext);

    const [isEmailEntered, setIsEmailEntered] = useState(false);
    const [emailLockedIn, setEmailLockedIn] = useState(false);
    const [isChecked, setEmailIsChecked] = useState(false);
    const [activeTab, setActiveTab] = useState(customerInfo.useSameBilling ? 'shipping' : 'billing');
    const emailRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const addressRef = useRef(null);
    const address2Ref = useRef(null);
    const cityRef = useRef(null);
    const stateRef = useRef(null);
    const postalCodeRef = useRef(null);

    // useEffect(() => {
    //     if (isEmailEntered) {
    //         // firstNameRef.current.focus();
    //     }
    // }, [isEmailEntered]);

    // useEffect(() => {
        
    // }, [customerInfo, billingInfo]);

    const isValidEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        
        // Test the email against the regex pattern
        return emailRegex.test(email);
    }

    const handleEmailChange = (e) => {
        setCustomerInfo({ ...customerInfo, email: e.target.value });
        handleEmailBlur();
    };

    const handleEmailBlur = () => {
        if (isValidEmail(customerInfo.email)) {
            setIsEmailEntered(true);
            setEmailLockedIn(true);
            setEmailIsChecked(true);
        } 
        else {
            setIsEmailEntered(false);
            setEmailLockedIn(false);
            setEmailIsChecked(false);
        }
    };

    const customerInfoChange = (inputType, field, value) => {
        if (inputType == 'customer') {
            setCustomerInfo({ ...customerInfo, [field]: value });
        } else {
            setBillingInfo({ ...billingInfo, [field]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            phoneHome(customerInfo, billingInfo);
            setShowOverlay(false);
        } else {
            alert('Please fill in all required fields and accept the Terms and Conditions.');
        }
    };

    const usStates = [
        { value: '', label: 'Select state' },
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
    ];

    const isFormValid = () => {
        if (customerInfo.useSameBilling) {
            const {
                firstName,
                lastName,
                email,
                address,
                city,
                state,
                postalCode,
                termsConditions,
            } = customerInfo;

            return (
                firstName.trim() !== '' &&
                lastName.trim() !== '' &&
                email.trim() !== '' &&
                address.trim() !== '' &&
                city.trim() !== '' &&
                state.trim() !== '' &&
                postalCode.trim() !== '' &&
                termsConditions
            );
        } else {
            const {
                firstName,
                lastName,
                email,
                address,
                city,
                state,
                postalCode,
                termsConditions,
            } = customerInfo;

            const {
                b_firstName,
                b_lastName,
                b_address,
                b_city,
                b_state,
                b_postalCode,
            } = billingInfo;            

            return (
                firstName.trim() !== '' &&
                lastName.trim() !== '' &&
                email.trim() !== '' &&
                address.trim() !== '' &&
                city.trim() !== '' &&
                state.trim() !== '' &&
                postalCode.trim() !== '' &&
                termsConditions &&
                b_firstName.trim() !== '' &&
                b_lastName.trim() !== '' &&
                b_address.trim() !== '' &&
                b_city.trim() !== '' &&
                b_state.trim() !== '' &&
                b_postalCode.trim() !== ''                
            );
        }
    };

    return (
        <div className="checkoutBox customer-container">
            <div className="checkout-section-heading">Please provide your email below</div>
            <div className="customer-wrap">
                <div className="email-container">
                    <div className="input-wrapper">
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your email"
                            ref={emailRef}
                            onKeyUp={handleEmailChange}
                            onChange={handleEmailChange}
                        />
                    </div>
                </div>
                {isEmailEntered && (
                    <>
                        {customerInfo.useSameBilling ? (
                            <>
                                <div className={`input-row tab-active`}>
                                    <div className="checkout-section-heading">Shipping Information</div>
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            id="firstName"
                                            placeholder="Enter your first name"
                                            value={customerInfo.firstName}
                                            onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                            ref={firstNameRef}
                                            disabled={!isEmailEntered}
                                        />
                                        <input
                                            type="text"
                                            id="lastName"
                                            placeholder="Enter your last name"
                                            value={customerInfo.lastName}
                                            onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                            ref={lastNameRef}
                                            disabled={!isEmailEntered}
                                        />
                                    </div>
                                </div>

                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="address"
                                        placeholder="Enter your address"
                                        value={customerInfo.address}
                                        onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                        ref={addressRef}
                                        disabled={!isEmailEntered}
                                    />
                                </div>

                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="address2"
                                        placeholder="Unit number"
                                        value={customerInfo.address2}
                                        onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                        ref={address2Ref}
                                        disabled={!isEmailEntered}
                                    />
                                </div>
                            
                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="city"
                                        placeholder="Enter your city"
                                        value={customerInfo.city}
                                        onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                        ref={cityRef}
                                        disabled={!isEmailEntered}
                                    />
                                </div>
                            
                                <div className="input-container">
                                    <select
                                        id="state"
                                        value={customerInfo.state}
                                        onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                        ref={stateRef}
                                        disabled={!isEmailEntered}
                                    >
                                        {usStates.map((state) => (
                                        <option key={state.value} value={state.value}>
                                            {state.label}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                            
                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="postalCode"
                                        placeholder="Enter your postal code"
                                        value={customerInfo.postalCode}
                                        onChange={(e) => customerInfoChange('customer', e.target.id, e.target.value)}
                                        ref={postalCodeRef}
                                        disabled={!isEmailEntered}
                                    />
                                </div>
                            </>
                        )   :  (
                            <>
                                <div className={`input-row tab-active`}>
                                    <div className="checkout-section-heading">Billing Information</div>
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            id="b_firstName"
                                            placeholder="Enter your first name"
                                            value={billingInfo.b_firstName}
                                            onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                            ref={firstNameRef}
                                            disabled={!isEmailEntered}
                                        />
                                        <input
                                            type="text"
                                            id="b_lastName"
                                            placeholder="Enter your last name"
                                            value={billingInfo.b_lastName}
                                            onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                            ref={lastNameRef}
                                            disabled={!isEmailEntered}
                                        />
                                    </div>
                                </div>

                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="b_address"
                                        placeholder="Enter your address"
                                        value={billingInfo.b_address}
                                        onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                        ref={addressRef}
                                        disabled={!isEmailEntered}
                                    />
                                </div>

                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="b_address2"
                                        placeholder="Unit number"
                                        value={billingInfo.b_address2}
                                        onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                        ref={address2Ref}
                                        disabled={!isEmailEntered}
                                    />
                                </div>
                            
                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="b_city"
                                        placeholder="Enter your city"
                                        value={billingInfo.b_city}
                                        onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                        ref={cityRef}
                                        disabled={!isEmailEntered}
                                    />
                                </div>
                            
                                <div className="input-container">
                                    <select
                                        id="b_state"
                                        value={billingInfo.b_state}
                                        onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                        ref={stateRef}
                                        disabled={!isEmailEntered}
                                    >
                                        {usStates.map((state) => (
                                        <option key={state.value} value={state.value}>
                                            {state.label}
                                        </option>
                                        ))}
                                    </select>
                                </div>
                            
                                <div className="input-container">
                                    <input
                                        type="text"
                                        id="b_postalCode"
                                        placeholder="Enter your postal code"
                                        value={billingInfo.b_postalCode}
                                        onChange={(e) => customerInfoChange('billing', e.target.id, e.target.value)}
                                        ref={postalCodeRef}
                                        disabled={!isEmailEntered}
                                    />
                                </div>                       
                            </>
                        )}
                        
                        <div className="input-container billingSame">
                            <input
                                type="checkbox"
                                id="useSameBilling"
                                defaultChecked={customerInfo.useSameBilling}
                                onChange={() => {
                                    setCustomerInfo({...customerInfo, useSameBilling: !customerInfo.useSameBilling});
                                    setActiveTab(customerInfo.useSameBilling ? 'billing' : 'shipping');
                                }}
                                disabled={!isEmailEntered}
                            />
                            <label htmlFor="useSameBilling" className="billing-label">
                                Billing Information is the same as Shipping
                            </label>
                        </div>

                        <div className="input-container terms-conditions">
                            <input
                                type="checkbox"
                                id="termsConditions"
                                defaultChecked={customerInfo.termsConditions}
                                onClick={(e) => {
                                    let isTermsChecked = e.target.checked;
                                    customerInfoChange('customer', e.target.id, isTermsChecked)
                                    if (!isFormValid() || !isTermsChecked) {
                                        setShowOverlay(true);
                                    }
                                }}
                                disabled={!isEmailEntered}
                            />
                            <label htmlFor="termsConditions" className="terms-label">
                                I agree to the <a href="/terms.html">Terms and Conditions</a>
                            </label>
                        </div>                        
                        <button
                            type="submit"
                            className="checkout-button"
                            disabled={!isFormValid() || !isEmailEntered}
                            onClick={handleSubmit}
                        >
                        Save and Continue
                        </button>                        
                    </>                    
                )}
            </div>
        </div>
    );
}


export default customerData;