import React, { useState } from 'react';
import './faq.css'; // import CSS file

const Faq = (props) => {
let steaks = props.steaks;

const faqs = [
        {
          id: 1,
          question: 'What is American Wagyu?',
          answer: 'Wagyu means "Japanese cattle" and is not the name of a breed of cattle. Our American Wagyu is locally raised, and created by breeding top tier Wagyu bloodlines imported directly from Japan with long-hailing superior Angus bloodlines raised in Arizona over generations. What you get is some of the most delicious and nutrient dense meats in the world. And at a price that everyone can afford.',
        },
        {
          id: 2,
          question: 'Do you ship?',
          answer: 'We proudly ship to the lower 48 states with 24 hour delivery. This ensures that these amazing American Wagyu steaks arrive to your door as fresh as if you bought them from the Farm yourself. Our custom air-tight insulated shipping containers keep the steaks completely frozen for up to 36 hours in direct sunlight and 100+ degree days (Arizona Tested). Most orders will be delivered within 12 hours of leaving our facility. *Deliveries Sent every Tuesday to ensure maximum shipping quality.',
        },
        {
          id: 3,
          question: 'How did this all start?',
          answer: 'Kleevr was started by two friends who had a passion for high quality amazing flavored steaks and other cuts of beef. Fast forward two years and we are now part owners with one of the top family owned American Wagyu Farms in the country, thanks to customers like you. You can always trust Kleevr to provide Top Tier, Hormone Free, Steroid Free, Antibiotic Free, Non GMO supplied beef. Whether your looking to find one of the most flavorful and consistent steaks for your next party, weekly cookout, or daily fitness regimen, Kleevr will always be there to offer you wholesale pricing, consistent quality and next day shipping*',
        },
    ];
    
return (
        <div id="faq-section">
            <div className="faq-title">Frequently Asked Questions</div>
            {faqs.map((faq, index) => (
                <div className="faq-item" key={index}>
                    <div className="faq-question">{faq.question}</div>
                    <div className="faq-answer">{faq.answer}</div>
                </div>
            ))}
            <p>For more information, please contact us at info@getkleevr.com</p>
        </div>
    );
}

export default Faq;